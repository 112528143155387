import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        ProfileVerifiedIcon,
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        userProfileState() {
            return this.activeUserWorkstation?.verificationStatus;
        },
        isVerified() {
            return this.user?.userIdentification?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
    }
});
