<template>
  <HeaderVerificationStatusSlot>
    <template #action-content>
      <div class="flex-row gap-half ai-c cursor-pointer">
        <Typography variant="p" textWeight="500">
          {{ getStatusText }}&nbsp; <span class="trade-passport-header-status-span">{{ getActionText }}</span>
        </Typography>
        <Icon iconName="arrow-right-yellow.svg" iconHeight=".8rem" iconWidth=".8rem"/>
      </div>
    </template>
  </HeaderVerificationStatusSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import HeaderVerificationStatusSlot from '@/core/components/slots/HeaderVerificationStatusSlot.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    Typography,
    Icon,
    HeaderVerificationStatusSlot
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation', 'activeUserWorkstation']),

    userProfileState() {
      return this.activeUserWorkstation?.verificationStatus;
    },

    isVerified() {
      return this.userProfileState === UserVerificationStatusEnum.VERIFIED;
    },

    getStatusText() {
      if (this.isVerified) {
        return 'Sole Trader or Limited Company?';
      }
      return 'Fully Unlock Iknowa';
    },

    getActionText() {
      if (this.isVerified) {
        return 'Create Your Trades Workstation?';
      }
      return 'Get Verified';
    },
  },
});
</script>
<style lang="scss" scoped>
.trade-passport-header-status-span {
    color: #FFA500 !important;
    font-weight: 700 !important;
}
</style>
